var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container--md-50 workPrepContainer" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "objection" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "div",
              {
                staticClass:
                  "elementPanel elementPanel__sub elementPanel__sub--withActionbar"
              },
              [
                _c(
                  "div",
                  { staticClass: "questionStepper" },
                  [
                    _c(
                      "v-stepper",
                      {
                        model: {
                          value: _vm.currentStep,
                          callback: function($$v) {
                            _vm.currentStep = $$v
                          },
                          expression: "currentStep"
                        }
                      },
                      [
                        _c(
                          "v-stepper-header",
                          [
                            _vm._l(_vm.steps.length, function(n) {
                              return [
                                _c(
                                  "v-stepper-step",
                                  {
                                    key: n + "-step",
                                    attrs: {
                                      color: "secondary",
                                      complete: _vm.currentStep > n,
                                      step: n,
                                      editable: "",
                                      "edit-icon": "check"
                                    }
                                  },
                                  [_vm._v(" Stap " + _vm._s(n) + " ")]
                                ),
                                n !== _vm.steps.length
                                  ? _c("v-divider", { key: n })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-stepper-items",
                          [
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "1" } },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Algemene gegevens")]
                                        ),
                                        _vm.reportId
                                          ? _c("DynamicForm", {
                                              attrs: {
                                                showApplicantSubmittedAtField: true,
                                                disabled: _vm.isLockedByUser,
                                                reportId: _vm.reportId,
                                                showTags: true,
                                                formTypes: ["tcmg"],
                                                currentEditor: "objection",
                                                sections: [2]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "2" } },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Bijlagen")]
                                        ),
                                        _vm.attachments &&
                                        _vm.attachments.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "v-table__overflow"
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "item__list item__list--table pb-3",
                                                    attrs: { wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass: "label",
                                                        attrs: {
                                                          wrap: "",
                                                          row: "",
                                                          "align-center": ""
                                                        }
                                                      },
                                                      [
                                                        _c("td", [
                                                          _c("span", [
                                                            _vm._v("Bijlage")
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Geüpload door"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Aangemaakt op"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("td", [
                                                          _c("span", [
                                                            _vm._v("Acties")
                                                          ])
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.attachments,
                                                        function(
                                                          attachment,
                                                          i
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "list__item"
                                                            },
                                                            [
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "item__name item__link"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href:
                                                                            attachment.original,
                                                                          target:
                                                                            "_blank"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "concatenateString"
                                                                              )(
                                                                                attachment.file_name
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _c("td", [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      attachment.uploaded_by
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _c("td", [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormatTime"
                                                                      )(
                                                                        attachment.created_at
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "item__icon item__icon--action"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href:
                                                                            attachment.original,
                                                                          target:
                                                                            "_blank"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "cloud_download"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                ),
                                                                !_vm.isLockedByUser
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item__icon item__icon--action delete"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteAttachment(
                                                                                  attachment
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ])
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Er zijn geen bijlagen"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "3" } },
                              [
                                _vm.isLoading
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading
                                  ? _c(
                                      "div",
                                      { staticClass: "stepContainer" },
                                      [
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Planning")]
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "dialog",
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      lazy: "",
                                                      "nudge-right": "100",
                                                      "full-width": "",
                                                      "max-width": "290px",
                                                      "min-width": "290px",
                                                      disabled:
                                                        _vm.isLockedByUser
                                                    },
                                                    model: {
                                                      value: _vm.isEditingDate,
                                                      callback: function($$v) {
                                                        _vm.isEditingDate = $$v
                                                      },
                                                      expression:
                                                        "isEditingDate"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "input input-date",
                                                      attrs: {
                                                        slot: "activator",
                                                        placeholder:
                                                          "DD-MM-JJJJ",
                                                        label: "Inplannen op",
                                                        "error-messages":
                                                          _vm.dateErrorMessage,
                                                        disabled:
                                                          _vm.isLockedByUser
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.dateFromTextField(
                                                            $event
                                                          )
                                                        }
                                                      },
                                                      slot: "activator",
                                                      model: {
                                                        value:
                                                          _vm.dateFormatted,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.dateFormatted = $$v
                                                        },
                                                        expression:
                                                          "dateFormatted"
                                                      }
                                                    }),
                                                    _c("v-date-picker", {
                                                      ref: "picker",
                                                      attrs: {
                                                        locale: "nl-nl",
                                                        min: "1910-01-01",
                                                        color: "#837f16",
                                                        "first-day-of-week": "1"
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.isEditingDate = false
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.date = $$v
                                                        },
                                                        expression: "date"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "input input-time",
                                                  attrs: {
                                                    slot: "activator",
                                                    mask: _vm.mask.time,
                                                    label:
                                                      "Inplannen op (tijd)",
                                                    "prepend-icon":
                                                      "access_time",
                                                    placeholder: "13:00",
                                                    disabled: _vm.isLockedByUser
                                                  },
                                                  slot: "activator",
                                                  model: {
                                                    value: _vm.planned_at_time,
                                                    callback: function($$v) {
                                                      _vm.planned_at_time = $$v
                                                    },
                                                    expression:
                                                      "planned_at_time"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "h2",
                                          { staticClass: "sectionTitle" },
                                          [_vm._v("Expert en Expertisebureau")]
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.organizations,
                                                    label: "Organisatie",
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    disabled: _vm.isLockedByUser
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedOrganization,
                                                    callback: function($$v) {
                                                      _vm.selectedOrganization = $$v
                                                    },
                                                    expression:
                                                      "selectedOrganization"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "field",
                                                attrs: { xs12: "" }
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    items: _vm.experts,
                                                    "search-input":
                                                      _vm.expertSearch,
                                                    label: _vm
                                                      .selectedOrganization
                                                      .length
                                                      ? "Koppel aan expert"
                                                      : "Kies eerst een organisatie",
                                                    "item-text": "name",
                                                    "item-value": "uuid",
                                                    "hide-details": "",
                                                    disabled:
                                                      !_vm.selectedOrganization
                                                        .length ||
                                                      _vm.isLockedByUser
                                                  },
                                                  on: {
                                                    "update:searchInput": function(
                                                      $event
                                                    ) {
                                                      _vm.expertSearch = $event
                                                    },
                                                    "update:search-input": function(
                                                      $event
                                                    ) {
                                                      _vm.expertSearch = $event
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.selectedExpert,
                                                    callback: function($$v) {
                                                      _vm.selectedExpert = $$v
                                                    },
                                                    expression: "selectedExpert"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass: "sectionTitle"
                                                  },
                                                  [_vm._v("Algemene opmerking")]
                                                ),
                                                _c("v-textarea", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLockedByUser,
                                                    label:
                                                      "Schadeomschrijving na contact met bewoner"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.damageDescription,
                                                    callback: function($$v) {
                                                      _vm.damageDescription = $$v
                                                    },
                                                    expression:
                                                      "damageDescription"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs6: "" } },
                              [
                                _vm.currentStep > 1
                                  ? _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          outline: "true",
                                          icon: "keyboard_arrow_left"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeStep("previous")
                                          }
                                        }
                                      },
                                      [_vm._v(" Vorige stap ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "text-right", attrs: { xs6: "" } },
                              [
                                _vm.currentStep < 3
                                  ? _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          icon: "navigate_next"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeStep("next")
                                          }
                                        }
                                      },
                                      [_vm._v(" Volgende stap ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.currentStep === 2
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "addAttachmentsButton",
                        attrs: {
                          absolute: "",
                          dark: "",
                          fab: "",
                          bottom: "",
                          right: "",
                          color: "#a09b1b",
                          disabled: _vm.isLockedByUser
                        },
                        on: {
                          click: function($event) {
                            _vm.isUploadingFile = true
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("add")])],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "success",
                                  small: "true",
                                  icon: "check_circle",
                                  disabled: _vm.isDisabled || _vm.isLockedByUser
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openApprovalDialog(
                                      "Doorzetten naar expert",
                                      "objection_in_progress"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Doorzetten naar expert ")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3034589474
              )
            }),
            _vm.approvalDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.approvalDialogOpen,
                      callback: function($$v) {
                        _vm.approvalDialogOpen = $$v
                      },
                      expression: "approvalDialogOpen"
                    }
                  },
                  [
                    _c("ApprovalDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        title: _vm.title,
                        status: _vm.status,
                        organization: _vm.selectedOrganization,
                        expert: _vm.selectedExpert,
                        damageDescription: _vm.damageDescription,
                        dateTime: _vm.dateTime,
                        dateField: _vm.dateField
                      },
                      model: {
                        value: _vm.approvalDialogOpen,
                        callback: function($$v) {
                          _vm.approvalDialogOpen = $$v
                        },
                        expression: "approvalDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isUploadingFile
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "750" },
                    model: {
                      value: _vm.isUploadingFile,
                      callback: function($$v) {
                        _vm.isUploadingFile = $$v
                      },
                      expression: "isUploadingFile"
                    }
                  },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "elementPanel__header",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__title",
                                      attrs: { sm11: "" }
                                    },
                                    [
                                      _c("h2", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isEditingAttachment
                                                ? "Bijlage aanpassen"
                                                : "Bijlage toevoegen"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__close",
                                      attrs: { sm1: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.closeUploadingFile(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              !_vm.isEditingAttachment
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "fileUpload__container",
                                      attrs: {
                                        "fill-height": "",
                                        "mb-3": "",
                                        "pa-1": "",
                                        wrap: ""
                                      }
                                    },
                                    [
                                      _c("MiFileUpload", {
                                        ref: "fileUpload",
                                        attrs: {
                                          filePath: _vm.uploadedFile,
                                          clearAfterUpload: false,
                                          multiple: true
                                        },
                                        on: {
                                          itemDropped: function($event) {
                                            return _vm.handleItemDropped($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isUploadingFileHistorical
              ? _c(
                  "v-dialog",
                  {
                    attrs: { persistent: true, "max-width": "750" },
                    model: {
                      value: _vm.isUploadingFileHistorical,
                      callback: function($$v) {
                        _vm.isUploadingFileHistorical = $$v
                      },
                      expression: "isUploadingFileHistorical"
                    }
                  },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "elementPanel__header",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__title",
                                      attrs: { sm11: "" }
                                    },
                                    [_c("h2", [_vm._v("Media toevoegen")])]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "header__close",
                                      attrs: { sm1: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.isUploadingFileHistorical = false
                                            }
                                          }
                                        },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                { staticClass: "createDialog" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("p", [
                                          _vm._v(
                                            " " + _vm._s(_vm.uploadText) + " "
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "fileUpload__container",
                                              attrs: {
                                                "fill-height": "",
                                                "pa-1": "",
                                                wrap: ""
                                              }
                                            },
                                            [
                                              _c("MiFileUpload", {
                                                ref: "fileUpload",
                                                attrs: {
                                                  filePath: _vm.uploadedFile,
                                                  clearAfterUpload: true
                                                },
                                                on: {
                                                  itemDropped: function(
                                                    $event
                                                  ) {
                                                    return _vm.handleItemDroppedHistorical(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }