import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { isValidDate, setFormattedDatePickerValue, dateErrorMessage } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { Report as ReportModel, Media } from '@/models/Report';
import { HistoricalReport } from '@/models/HistoricalReport';
import { FileType } from '@/views/Reports/Report';
import { DateTime } from 'luxon';
import { Organization } from '@/models/Organization';
import { AlertBar } from '@/support/AlertBar';
import { Lock } from '@/support/Lock';
import IdleWarningDialog from '@/components/IdleWarningDialog';

@Component<WorkPreparationObjection>({})
export default class WorkPreparationObjection extends IdleWarningDialog {
  public $pageTitle = 'Bezwaarrapporten';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected reportModel: ReportModel = new ReportModel();

  protected reportId = '';

  protected isLoading = true;

  protected currentStep = 1;

  protected steps: string[] = ['1', '2', '3'];

  protected currentHistoricalDamage: HistoricalReport | null = null;

  protected date = '';

  protected isEditingDate = false;

  protected perspectivePictures: Media[] = [];

  protected mapPictures: Media[] = [];

  protected attachments: Media[] = [];

  protected isEditingAttachment = false;

  protected selectedMedia: Media | null = null;

  protected isUploadingFileHistorical = false;

  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected uploaded = false;

  protected fileCounter = 0;

  protected uploadQueue: File[] = [];

  protected postTimeOut: any | null = null;

  protected isEditingPlannedAt = false;

  protected planned_at_date = '';

  protected planned_at_time = '13:00';

  protected selectedOrganization = '';

  protected organizations: any[] = [];

  protected organizationExperts: any[] = [];

  protected experts: any[] = [];

  protected expertSearch = '';

  protected selectedExpert = '';

  protected damageDescription = '';

  protected dateTime = '';

  protected fileTypes: FileType[] = [
    {
      label: 'Offerte',
      value: 'offerte',
    },
    {
      label: 'Addendum',
      value: 'addendum',
    },
    {
      label: 'Nader onderzoek',
      value: 'nader_onderzoek',
    },
  ];

  protected fileDescription = '';

  protected selectedFile: File | null = null;

  protected selectedAttachment: Media | null = null;

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  // approval bar
  protected approvalDialogOpen = false;

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.date = DateTime.local().toFormat('yyyy-MM-dd');
    this.reportId = this.$route.params.id;
    this.initialize();
  }

  protected mask: Mask = {
    time: '##:##',
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle schades', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Werkvoorbereiding bezwaar rapport' },
        ],
      });
  }

  protected initialize() {
    this.isLoading = true;
    this.getOrganizations();
    this.reportModel
      .include(['damages', 'media', 'reject_reasons'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.isLoading = false;
        this.filterMedia();
        this.initBreadcrumb();

        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:workpreparationobjection`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected destroyed() {
    this.lock.unlock(this.lockKey);
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  // DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.dateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingDate) {
      this.dateFromDatePicker();
    }
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((response: Organization[]) => {
        this.organizations = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizationExperts() {
    new Organization()
      .include('experts')
      .find(this.selectedOrganization)
      .then((response: Organization) => {
        this.experts = response.experts;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected filterMedia() {
    if (! this.reportModel.media) {
      return;
    }

    this.perspectivePictures = this.reportModel.media.filter((media: Media) => media.type === 'picture');

    this.mapPictures = this.reportModel.media.filter((media: Media) => media.type === 'floorplan');

    this.attachments = this.reportModel.media.filter((media: Media) => media.type !== 'floorplan' && media.type !== 'picture');
  }

  protected deleteAttachment(media: Media) {
    this.selectedMedia = media;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteReportImage);
  }

  protected get dialogOptionsDeleteReportImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.reportModel || ! this.selectedMedia) {
              return;
            }

            this.reportModel
              .deleteMediaEndpoint(this.selectedMedia.uuid ? this.selectedMedia.uuid : '')
              .then((response: AxiosResponse) => {
                this.initialize();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedMedia = null;
          },
        },
      },
    };
  }

  protected parseDate(date: string) {
    if (! date) {
      return '';
    }
    if (date.length < 12) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy');
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss').toFormat('dd-MM-yyyy');
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected postFiles() {
    if (! this.uploadQueue.length) {
      return;
    }

    new ReportModel()
      .mediaEndpoint(this.reportModel.uuid ? this.reportModel.uuid : '')
      .create({ file: this.uploadQueue[0] })
      .then((response: AxiosResponse) => {
        const queueCopy = [...[], ...this.uploadQueue];
        queueCopy.shift();
        this.uploadQueue = queueCopy;

        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultiple(this.fileCounter);

        if (this.uploadQueue.length <= 0) {
          this.fileCounter = 0;
        } else {
          setTimeout(() => {
            this.fileCounter += 1;
            this.postFiles();
          }, 500);
        }
      })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });
  }

  protected changeStep(direction: string) {
    if (direction === 'next') {
      this.currentStep ++;
    } else if (direction === 'previous') {
      this.currentStep --;
    }
  }

  protected postReportFile(file: File | string) {
    new ReportModel()
      .mediaEndpoint(this.reportModel.uuid ? this.reportModel.uuid : '')
      .create({ file })
      .then((response: AxiosResponse) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploaded();
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get uploadText() {
    if (! this.uploaded) {
      return 'Upload een bestand in:';
    }

    return 'Upload nog een bestand in:';
  }

  protected closeUploadingFile() {
    this.isUploadingFile = false;
    this.uploadedFile = '';
    this.selectedFile = null;
    this.fileCounter = 0;
    this.initialize();
  }

  protected parseTime(time: string) {
    if (time.includes(':')) {
      // eslint-disable-next-line no-self-assign
      time = time;
    } else {
      const timeSplit = time.match(/.{1,2}/g);

      if (timeSplit) {
        time = `${timeSplit[0]}:${timeSplit[1]}`;
      }
    }

    return `${time}:00`;
  }

  // approval bar
  protected title = '';

  protected status = '';

  protected dateField = '';

  protected openApprovalDialog(title: string, status: string, dateField = '') {
    if (! this.selectedOrganization || ! this.selectedExpert) {
      this.isDisabled = true;
      return;
    }

    this.status = status;
    this.title = title;
    this.dateField = dateField;

    this.approvalDialogOpen = true;
  }

  protected set isDisabled(value) {
    this.isDisabled = value;
  }

  protected get isDisabled(): boolean {
    const hasDateError = this.dateErrorMessage.length > 0;

    if (this.selectedExpert && this.selectedExpert.length && this.selectedOrganization && this.selectedOrganization.length && ! hasDateError) {
      return false;
    }
    return true;
  }

  @Watch('selectedOrganization')
  protected selectedOrganizationChanged(to: any, from: any) {
    this.getOrganizationExperts();
  }

  @Watch('formattedDate')
  @Watch('planned_at_date')
  @Watch('planned_at_time')
  public planned_at_dateChanged() {
    this.reportModel.planned_at = `${this.planned_at_date} ${this.planned_at_time}`;
    this.dateTime = `${this.date} ${this.parseTime(this.planned_at_time)}`;
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.postFiles();
    }, 20);
  }

  @Watch('currentStep')
  protected currentStepChanged() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

interface Mask {
  [key: string]: string;
}
